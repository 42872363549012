








import { Component, Vue } from "vue-property-decorator";
import ListaReservas from "@/components/Reserva/ListaReservas.vue";

@Component({
  components: {
    ListaReservas
  }
})
export default class ListaReservasView extends Vue {}
