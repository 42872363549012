




























































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
@Component
export default class ListaReservas extends Mixins(FormValidator) {
  private reservas: any = [];

  private mounted() {
    this.getReserva();
  }

  private getReserva() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/lista/reservas")
      .then((res: any) => {
        this.reservas = res.data;
        const base = process.env.VUE_APP_BASE_URL;
        for (let i = 0; i < this.reservas.length; i++) {
          if (this.reservas[i].foto) {
            this.reservas[i].foto = base + this.reservas[i].foto;
          } else {
            this.reservas[
              i
            ].foto = require("@/assets/Reservas/banner_desktop.png");
          }
          if (this.reservas[i].foto_mobile) {
            this.reservas[i].foto_mobile = base + this.reservas[i].foto_mobile;
          } else {
            this.reservas[
              i
            ].foto_mobile = require("@/assets/Reservas/banner_mobile.png");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.reservas = [];
      });
  }

  private goReserva(nombre_web: string) {
    if (nombre_web && nombre_web != "") {
      if (
        this.$router.currentRoute.name == "Reserva" &&
        this.$router.currentRoute.params.nombre &&
        this.$router.currentRoute.params.nombre == nombre_web
      ) {
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == "Reserva") {
          if (
            this.$router.currentRoute.params.nombre &&
            this.$router.currentRoute.params.nombre != nombre_web
          ) {
            this.$router
              .push({ name: "Reserva", params: { nombre: nombre_web } })
              .catch(err => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$router
              .push({ name: "Reserva", params: { nombre: nombre_web } })
              .catch(err => {
                console.log(err);
              });
          }
        } else {
          this.$router
            .push({ name: "Reserva", params: { nombre: nombre_web } })
            .catch(err => {
              console.log(err);
            });
        }
      }
    }
  }
}
